import React, { useState, useEffect } from 'react';

const VideoLoader = ({ video, videoControl, videoAutoplay, videoMuted, videoLoop, videoWidth }) => {
    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await fetch(`https://pazmj7mw7h.execute-api.eu-west-1.amazonaws.com/default/fezzik-functions`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ videos: [video] }), 
                });

                if (response.ok) {
                    const data = await response.json();
                    setVideoUrl(data); 
                } else {
                    console.error('Error fetching videos');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchVideos();
    }, []); 



    return (
        <div className=''>
            <video src={videoUrl} preload="auto" controls={videoControl} autoPlay={videoAutoplay} muted={videoMuted} loop={videoLoop} style={{ maxWidth: videoWidth }}/>


        </div>

    );
};

export default VideoLoader;