import React, { useState, useEffect, useRef } from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

import arrowIcn from '../../assets/icns/arrow.svg'; 

import navina01 from '../../assets/imgs/navina/navina01.png'; 
import navina02 from '../../assets/imgs/navina/navina02.png'; 
import navina03 from '../../assets/imgs/navina/navina03.png'; 
import navina04 from '../../assets/imgs/navina/navina04.png'; 


import reviewerImg from '../../assets/imgs/navina/reviewer.png'; 
import VideoLoader from '../../functions/VideoURLLoader';









const NavinaPage = () => {


  const redirectToArrows = () => {
    window.location.href = '/works/arrows';
  };
  
  const redirectToVayyar = () => {
    window.location.href = '/works/vayyar';
  };
  

  const videoList = [
    { folderName: 'navina', fileName: 'navina-mackbook-screen01.mp4' },


  ];


  const videoListScroll = [
    { folderName: 'navina', fileName: 'navina_mockups0001.mp4' },
    { folderName: 'navina', fileName: 'navina_mockups0002.mp4' },
    { folderName: 'navina', fileName: 'navina_mockups0003.mp4' },
    { folderName: 'navina', fileName: 'navina_mockups0004.mp4' },
    { folderName: 'navina', fileName: 'navina_mockups0005.mp4' },

  ];



  const [currentIndex, setCurrentIndex] = useState(1);
  const videoRefs = useRef([]);

  useEffect(() => {
      videoRefs.current.forEach(video => {
          if (video) {
              video.load();
          }
      });
  }, []);




  
  const prevSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? videoListScroll.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex === videoListScroll.length - 1 ? 0 : prevIndex + 1));
  };



  return (
    <div className='row p-0 m-0'>
    <Header/>
    <div className='header-height'></div>

    <div className='p-md-5'>
        <div className='row p-0 m-0 '>
          <div className='col-sm-1 col-xs-12'></div>
          <div className='col-sm-11 col-xs-12'>
          <div className='row'>
              <div className='col d-flex'>
                  <h4 className='fw-500 fs-28 capitalize'>Navina</h4>
              </div>
                <div className='col d-flex float-end justify-content-end'>
                  <p className='fs-18 fw-300'>2023</p>
                </div>
             </div>
                <div className='col-12'>
                    <h4 className=' fw-400'>
                      Enhancing Navina's Website Experience Through Web Animations
                    </h4>
                <img className='pt-5 img-fluid' src={navina01} alt=""/>
            </div>     
          </div>
        </div> 



          <div className='row px-5 py-5 m-0'>

          <div className='col-sm-1 col-xs-12 '>
          </div>

              <div className='col-sm-10 col-xs-12 py-5'>
                <div className='d-flex col-9'>
                  <p className='fs-22 fw-200'>
                      Navina ends frustrating EHR data hunting with an actionable Patient Portrait that allows physicians to review any case in minutes. Navina empowers physicians to be more prepared for visits and creates space for meaningful patient interactions.
                  </p>
                </div>
 
                  <div className='py-3'></div>

                    <div className='col-12'>
                      <p className='fs-18 fw-400'>This work includes</p>
                        <div className='row'>
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Capabilities</h4>
                              <ul className='p-0'>
                                <li className='py-1'>UX/UI Review</li>
                                <li className='py-1'>Design System</li>
                                <li className='py-1'>Web Animation</li>
                                <li className='py-1'>Product Design</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Team</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Product Manager</li>
                                <li className='py-1'>DX Designer</li>
                                <li className='py-1'>Animator</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Cooperation</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project</li>
  
                              </ul>
                          </div>

                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Industry</h4>
                                <li className='py-1'>Healthcare</li>
                                <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                                <li className='py-1'>2 Months, ongoing</li>

                          </div>

                        </div>

                        <div className='py-3'></div>
    
                        {/* <div className='col d-flex'>
                            <h4 className='fs-22 fw-600'>Reviews</h4>
                        </div> */}
                        <div className='my-3 '></div>

                      <div className='d-flex'>
                          <h4 className='fs-22 fw-700 dark-text'><a href='https://navina.ai' target='blank'>Visit Website &nbsp;↗</a> </h4>
                        </div>

                        </div>



                </div>

          </div>






          <div className='row px-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
              <h4 className='fw-400 fs-22 pb-3 opacity-50'>Approach</h4>
                <p className='fs-22 fw-200'>

                     Navina's product is structured from many views and boards that deliver data from and for the EHR. We set a meeting with Navina's head of marketing, a UX designer, and our animator to get the main strength of the product. After reviewing the product, we selected the main sections that would catch viewers' attention, and with them, we moved forward into the animation process. We designed each storyboard flow in the most minimalized way possible to reduce unnecessary information while keeping each animation up to 25 seconds. The idea was to make an animation user guide to show what the product can do and how to use it. In this way, we could make the website the first demo impression of the product.
                        We decided to make the animation in 2D, using the Lottie format, which makes each animation as light as possible to reduce the website's loading time.
                  </p>
            </div>
          </div>



          <div className='row m-0 px-5'>
            <div className='col-6 p-2'>
                <img
                  src={navina02}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <img
                  src={navina03}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <img
                  src={navina04}
                  width='100%'
                />
            </div>
          </div>
 


          <div className='row px-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
              <h4 className='fw-600 fs-22 pb-3 opacity-50'>Outcome</h4>
              <h4 className='fw-300 fs-42 pb-3'>Minimalize massive data into simple-to-understand product</h4>
                {/* <p className='fs-22 fw-400'>
                We took the mockups of Navina's platform and then translated each mockup to a minimalistic wireframe that would mark only the important points the relevant video explains. Each wireframe moved to the 3D world to stick to the authentic look of the platform.
                      The motion planned to give the video interesting moves while keeping the ability to track and see the changes made during the video.
                </p> */}
            </div>
          </div>


          <div className='row px-5 m-0'>
            <div className='position-relative'>
                <div className="slider">
                  <button className="slider-button prev" onClick={prevSlide}>
                      <img src={arrowIcn} className='rotate-180' width={'35px'} alt="Previous" />
                    </button>
                    <div className="slider-video">
                    <div className='col-12 my-5 video'>
                          <VideoLoader video={videoListScroll[currentIndex]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%"/>
                      </div>
                    </div>

                    <button className="slider-button next" onClick={nextSlide}>
                      <img src={arrowIcn} width={'35px'}  alt="Next" /> 
                    </button>
                </div>
              </div>
            </div>


            

          <div className='row px-sm-5 py-5 m-0'>
            <div className='col-12 px-5 py-5 text-center'>
              <h4 className='fw-400 fs-22 pb-3 opacity-50'>Results</h4>
              <h4 className='fs-42 fw-300'>Easy Explains Navina's Product</h4>
                {/* <p className='fs-22 fw-400'>
                We took the mockups of Navina's platform and then translated each mockup to a minimalistic wireframe that would mark only the important points the relevant video explains. Each wireframe moved to the 3D world to stick to the authentic look of the platform.
                      The motion planned to give the video interesting moves while keeping the ability to track and see the changes made during the video.
                </p> */}

              <div className='row'>
                  <div className='col-sm-11 col-xs-12 container capitalize'>
                      <div className='row px-5 py-4'>
                        <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className=' fw-700'>60%</h4>
                              <p className='fs-24 fw-400 '>more engagments</p>
                          </div>
                          <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className=' fw-700'>50%</h4>
                              <p className='fs-24 fw-400 '>increase in website time view</p>
                          </div>
                          <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className=' fw-700'>55%</h4>
                              <p className='fs-24 fw-400 '>growth in leads</p>
                          </div>

                      </div>
                    </div>
                  </div>
              </div>
          </div>


          <div className='row mb-4 px-5'>
            <div className='col-12 my-5'>
                <VideoLoader video={videoList[1]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%" />
            </div>
          </div>

          <div className='row px-sm-5 pt-4 pb-5 m-0'>
              <div className='col-12 px-5 pb-5 pt-4'>
                  <p className='fs-22 fw-200'>
                      The team went above and beyond by making themselves readily available to address our needs and concerns, fostering a seamless and collaborative working relationship. We are genuinely appreciative of their dedication, and their exceptional project management significantly contributed to the overall success of our project. Their collaborative spirit, responsiveness, and commitment to excellence truly stood out, making them an exceptional partner in achieving our goals.
                  </p>

                  <div className='d-flex pt-4'>
                    <img
                    src={reviewerImg}
                    width='60px'
                    height='60px'
                    />
                    <p className='fs-18 fw-300 ms-3'>Niva Ziv<br></br>
                      <span className='fw-500 fs-16'>Director of Demand and Growth Marketing</span></p>
                  </div>
              </div>
          </div>



          <div className='row px-sm-5 m-0'>
              <div className='col d-flex'>
                  <p onClick={redirectToVayyar} className='fw-600 pointer'>Previous</p>
              </div>
              <div className='col d-flex text-right justify-content-end '>
                  <p onClick={redirectToArrows} className='fw-600 pointer'>Next</p>
              </div>
          </div>  


            <div className='row pb-5 px-sm-5 m-0'>
            </div>
        </div>


        
    </div>

    

    
  );
};


export default NavinaPage;


