import React from 'react';
import LinkedinIcn from '../assets/icns/linkedin-icn.svg';
import InstagramIcn from '../assets/icns/instagram-icn.svg';



const Footer = () => {

    const redirectToProposal = () => {
        window.location.href = '/proposal';
      };
    

    return (

        <footer className="footer">
            <div className="container-fluid d-flex justify-content-between align-items-center">
                <div className="footer-left">
                    <a className='fs-14 text-dark fw-500 pe-2' href="https://www.linkedin.com/company/fezzik-art/?viewAsMember=true"><img width={'30px'} src={LinkedinIcn}/></a>
                    <a className='fs-14 text-dark fw-500 pe-2' href="https://www.instagram.com/fezzikart/"><img width={'30px'} src={InstagramIcn}/></a>
                </div>
                <div className="footer-right">
                <p className='float-end fs-14 pe-5 text-dark fw-200'>anmetz ltd 2024</p>
                    <a className='fs-14 text-dark fw-500 pe-3' href="/sitemap">&nbsp;Sitemap</a>
                    <a className='fs-14 text-dark fw-500 ' href="mailto:hello@fezzik.art">Email</a>
                </div>
            </div>
            </footer>


    );
}

export default Footer;
