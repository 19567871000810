import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import logoLight from './logo-light.svg';
import logoDark from './logo-dark.svg';
import whatsapp from '../assets/icns/whatsapp-icn.svg';
import menu from '../assets/icns/menu-icn.svg';
import mail from '../assets/icns/mail-icn.svg';



import Menu from './Menu';
import MenuServices from './ServicesMenu';
import MenuWorks from './WorksMenu';


const Header = ({ isDarkBackground }) => {

  // const logoSrc = isDarkBackground ? logoLight : logoDark ;
  // const menuIcn = isDarkBackground ? menuLight : menuDark ;
  const location = useLocation();



  const [isOpen, setIsOpen] = useState(false);


    

    const openMenu = () => {

        document.body.style.overflow = 'hidden'; 
    };

    const closeMenu = () => {

      document.body.style.overflow = 'auto'; 
    };

    // const openServicesMenu = () => {
    //   setIsMenuServicesOpen(true);
    //   document.body.style.overflow = 'hidden';
    // };

    // const closeServicesMenu = () => {
    //   setIsMenuServicesOpen(false);
    //   document.body.style.overflow = 'auto';

    // };

    // const openWorksMenu = () => {
    //   setisMenuWorksOpen(true);
    //   document.body.style.overflow = 'hidden';
    // };

    // const closeWorksMenu = () => {
    //   setisMenuWorksOpen(false);
    //   document.body.style.overflow = 'auto';

    // };





  return (
    <div className='header'>
            {isOpen && <Menu isDarkBackground={true} setIsMenuOpen={setIsOpen} />}


    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        {/* Logo on the left */}
        <a className="" href="/">
          <img src={logoDark} alt="Logo" width="90" height="auto" />
        </a>



        {/* Centered Menu Links */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto text-dark">
            <li className="">
              <a className="nav-link me-4" href="/works"><p className={`text-dark fs-18 fw-500 ${location.pathname === '/works' ? 'fw-700' : 'fw-500'}`}>Works</p></a>
            </li>
            <li className="">
              <a className="nav-link me-4" href="/about"><p className={`text-dark fs-18 fw-500 ${location.pathname === '/about' ? 'fw-700' : 'fw-500'}`}>About</p></a>
            </li>
            <li className="">
              <a className="nav-link" href="/services"><p className={`text-dark fs-18 fw-500 ${location.pathname === '/services' ? 'fw-700' : 'fw-500'}`}>Services</p></a>
            </li>
          </ul>
        </div>


        <div className=" d-none d-lg-block">
          <div className="d-inline-flex btn-outline-gradient me-2 rounded-5 border-0 px-3" type="button" ><a href="/contact" className='m-0 fs-16 fw-600 capitalize btn-text  dark-text'>lift off 🔥</a></div>
          <div className="btn-contact d-inline-flex rounded-5 border-dark-2px px-3 py-1" ><a href="mailto:hello@fezzik.art" className='m-0 fs-16 fw-700'>Contact Us</a></div>
        </div>

        <div className="d-flex d-lg-none">
          <img onClick={() => { setIsOpen(!isOpen);}} src={menu} alt="menu" width="30" height="auto" />
            {/* <a href="mailto:hello@fezzik.art" target='blank'
              className='pointer ms-2'>
                <img width="40" height="30px" src={mail}/>
            </a> */}
        </div>


      </div>
    </nav>




        </div>
      );
    }
  
  export default Header;
  