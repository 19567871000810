import React, { useState, useEffect } from 'react';
import Header from '../header/Header';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import otterize from '../assets/imgs/otterize/cover-img.png';
import navina from '../assets/imgs/navina/navina00.png';
import spark from '../assets/imgs/spark/cover-img.png';
import clariter from '../assets/imgs/clariter/clariter00.png';
import vayyar from '../assets/imgs/vayyar/cover-img.png';
import astrix from '../assets/imgs/astrix/cover-img.png';
import geoxITC from '../assets/imgs/geox/geoxITC_00.jpg';
import arrows from '../assets/imgs/arrows/cover-img.png';
import VideoLoader from '../functions/VideoURLLoader';

import navinaLogo from '../assets/imgs/companies/navina.png';
import komodorLogo from '../assets/imgs/companies/komodor.png';
import geoxLogo from '../assets/imgs/companies/geox.png';
import linkedinLogo from '../assets/imgs/companies/linkedin.png';
import ebayLogo from '../assets/imgs/companies/ebay.png';
import astrixLogo from '../assets/imgs/companies/astrix.png';
import clariterLogo from '../assets/imgs/companies/clariter.png';
import monetLogo from '../assets/imgs/companies/monet.png';
import otterizeLogo from '../assets/imgs/companies/otterize.png';
import vayyarLogo from '../assets/imgs/companies/vayyar.png';



const logos = [
  { src: navinaLogo, alt: 'Logo 1' },
  { src: ebayLogo, alt: 'Logo 1' },
  { src: komodorLogo, alt: 'Logo 1' },
  { src: geoxLogo, alt: 'Logo 1' },
  { src: linkedinLogo, alt: 'Logo 1' },
  { src: astrixLogo, alt: 'Logo 1' },
  { src: clariterLogo, alt: 'Logo 1' },
  { src: monetLogo, alt: 'Logo 1' },
  { src: otterizeLogo, alt: 'Logo 1' },
  { src: vayyarLogo, alt: 'Logo 1' },


];



const LogoCarousel = () => {
  const settings = {
    dots: false,                 // No pagination dots
    infinite: true,              // Infinite looping
    speed: 500,                  // Transition speed
    slidesToShow: 5,             // Number of logos visible at once
    slidesToScroll: 1,           // Logos to scroll per swipe
    autoplay: true,              // Enable autoplay
    autoplaySpeed: 2000,         // Autoplay interval
    responsive: [
      {
        breakpoint: 1024,        // Tablet breakpoint
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 768,         // Mobile breakpoint
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <div className="logo-carousel">
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="logo-slide">
            <img src={logo.src} alt={logo.alt} className="logo-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};




const HomePage = () => {
    // const [title, setTitle] = useState(['fezzik is a global creative Production House that brings brands, stories, and experiences to life through art, design, and technology.']);
    const [title, setTitle] = useState(['We are a creative production house that accelerates business growth through the power of art, design, and technology']);

    
    const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % title.length);
      }, 2000); 
      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }, [title]);
  


    const videoList = [
        { folderName: 'fezzik', fileName: 'fezzik_emojies_01.mp4' },
        { folderName: 'geox', fileName: 'geox_demo01.mp4' },
        { folderName: 'geox', fileName: 'geox-hero.mp4' },
      ];



      const redirectToContact = () => {
        window.location.href = '/contact';
      };
    const redirectToOtterize = () => {
        window.location.href = '/works/otterize';
      };
    const redirectToNavina = () => {
        window.location.href = '/works/navina';
      };
    const redirectToArrows = () => {
        window.location.href = '/works/arrows';
      };
    const redirectToSpark = () => {
        window.location.href = '/works/spark';
      };
      const redirectToVayyar = () => {
        window.location.href = '/works/vayyar';
      };
      const redirectToClariter = () => {
        window.location.href = '/works/clariter';
      };
      const redirectToAstrix = () => {
        window.location.href = '/works/astrix';
      };
      const redirectToGeoxITC = () => {
        window.location.href = '/works/geoxITC';
      };


      

  return (
    <div className='light-background row mx-auto h-100'>
      <Header/>
            <div className='row align-items-center pt-3 mb-4'>


                <div className='col-lg-9 col-sm-12 mx-auto'>
                        <h1 className='text-center'>
                            {title[0]}
                        </h1>



                <div className='col-lg-12 col-md-10 col-sm-12 col-xs-12 justify-content-center text-center'>
                    <p className='pt-3 fw-200 fs-24'>
                    Unleashing Creativity Through Animation – Elevate Your Brand with Engaging, High-Quality Animated Content
                    </p>
                
                <div className='mt-4'>
                    <div className="d-inline-flex cta-button me-2 rounded-5 px-4 py-2" type="button" ><a href="/contact" className='m-0 fs-16 fw-700 capitalize btn-text dark-text'>Free Quote</a></div>
                        </div>

                    
                </div>         
            </div>
            </div>


        <div className='row mt-5 px-sm-5 mx-0 align-items-top'>
            <div className='col-sm-8 col-xs-12'>
                <div onClick={redirectToGeoxITC} className='image-container pointer' >
                    <VideoLoader video={videoList[1]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%" />
                </div>
                    <div className='row mt-4 text-dark'>
                            <div className=''>
                                <h5 onClick={redirectToGeoxITC} className='fw-500 capitalize pointer'>geox ITC 2024</h5>
                                    <p className='fs-18 fw-300'>Search, collect and save accurate property insights</p>
                            </div>
                            <div className='float-end text-right'>
                                <p className='fs-16 fw-500'>Booth Design</p>
                            </div>
                        </div>
                </div>

                <div className='col-sm col-xs-12'>
                    <div onClick={redirectToNavina} className='image-container pointer' >
                        <img className='img-fluid' src={navina} alt='otterize-cover' width={1920} height={1080}/>
                        </div>
                        <div className='row mt-4 text-dark'>
                            <div className=''>
                                <h5 onClick={redirectToGeoxITC} className='fw-500 capitalize pointer'>Navina product website</h5>
                                    <p className='fs-18 fw-300'>Empowering Patient Care Through Clear, Impactful Animated demos</p>
                            </div>
                            <div className='float-end text-right'>
                                <p className='fs-16 fw-500'>Web Animations</p>
                            </div>
                        </div>
                    </div>


        <div className='row p-0 m-0 justify-content-center mt-sm-4'>
            <div className='col-sm-5 col-xs-12'>
                <div onClick={redirectToOtterize} className='image-container pointer' >
                    <img className='img-fluid' src={otterize} alt='otterize-cover' width={1920} height={1080}/>
                </div>
                <div className='row mt-4 text-dark'>
                            <div className=''>
                                <h5 onClick={redirectToOtterize} className='fw-500 capitalize pointer'>Explaining Otterize Product</h5>
                                    <p className='fs-18 fw-300'>Kubernetes security that gives a damn about developers</p>
                            </div>
                            <div className='float-end text-right'>
                                <p className='fs-16 fw-500'>Explainer Video</p>
                            </div>
                        </div>
                </div>

                <div className='col-sm col-xs-12'>
                    <div onClick={redirectToVayyar} className='image-container pointer' >
                        <img className='img-fluid' src={vayyar} alt='otterize-cover' width={1920} height={1080}/>
                        </div>
                        <div className='row mt-4 text-dark'>
                            <div className=''>
                                <h5 onClick={redirectToVayyar} className='fw-500 capitalize pointer'>Vayyar Care App</h5>
                                    <p className='fs-18 fw-300'>Brightening the installation process with an animated guide</p>
                            </div>
                            <div className='float-end text-right'>
                                <p className='fs-16 fw-500'>App Animations</p>
                            </div>
                        </div>
                    </div>
                </div>



            <div className='row p-0 m-0 justify-content-center mt-sm-4'>
                    <div className='col-sm-5 col-xs-1'>
                        <div onClick={redirectToAstrix} className='image-container pointer' >
                        <img className='img-fluid' src={astrix} alt='navina-cover' width={1920} height={1080}/>
                        </div>
                        <div className='row mt-4 text-dark'>
                            <div className=''>
                                <h5 onClick={redirectToAstrix} className='fw-500 capitalize pointer'>Non-Human Identity Security</h5>
                                    <p className='fs-18 fw-300'>An explainer video created to explain Astrix Security</p>
                            </div>
                            <div className='float-end text-right'>
                                <p className='fs-16 fw-500'>Explainer Video</p>
                            </div>
                        </div>

                    </div>

                    <div className='col-sm-5 col-xs-12'>
                        <div onClick={redirectToArrows} className='image-container pointer' >
                        <img className='img-fluid' src={arrows} alt='navina-cover' width={1920} height={1080}/>
                        </div>
                        <div className='row mt-4 text-dark'>
                            <div className=''>
                                <h5 onClick={redirectToArrows} className='fw-500 capitalize pointer'>Human Avatar Interaction</h5>
                                    <p className='fs-18 fw-300'>Practicing social interaction skills with Arrows</p>
                            </div>
                            <div className='float-end text-right'>
                                <p className='fs-16 fw-500'>Game Development</p>
                            </div>
                        </div>

                    </div>
                </div>



                </div>







            <div className='header-height py-5'></div>


            <div className='row align-items-center pt-3 mb-4'>
                <div className='col-lg-9 col-sm-12 mx-auto'>
                    <div className='py-2 text-center'>
                        <h6 className='fw-400 capitalize'>
                        trusted by teams at
                        </h6>

                        <LogoCarousel/>


                    </div>
            </div>
            </div>


            <div className='py-3'></div>


            <div className='row align-items-center pt-3 mb-4'>
                <div className='col-lg-9 col-sm-12 mx-auto mb-5'>
                    <div className='text-center'>
                        <h2 className='fw-400 capitalize pb-2'>
                            Services we provide
                        </h2>

                        <p className='fs-22 fw-300 capitalize'>
                        We create fully customized productions tailored precisely to match your business’s unique style and needs. Our work is crafted from scratch — no generic templates, no compromises. With a commitment to excellence, we deliver high-quality, impactful content that sets your brand apart
                        </p>
                    </div>
            </div>




    <div className="row p-0 mx-auto">
    <div className='col-lg-10 col-xs-12 mx-auto'>
    <div className="row p-0 mx-auto">
        <div className="col-6 col-md-3 p-2">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                Website and App Animations
                </h6>
            </div>
        </div>
        <div className="col-6 col-md-3 p-2">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                Explainer Videos
                </h6>
            </div>
        </div>
        <div className="col-6 col-md-3 p-2 roudned-4">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                Product Videos
                </h6>
            </div>
        </div>
        <div className="col-6 col-md-3 p-2">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                Video for Shows
                </h6>
            </div>
        </div>
        <div className="col-6 col-md-3 p-2">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                TV Campaigns
                </h6>
            </div>
        </div>
        <div className="col-6 col-md-3 p-2">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                Training Productions
                </h6>
            </div>
        </div>
        <div className="col-6 col-md-3 p-2">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                3D Visualizations
                </h6>
            </div>
        </div>
        <div className="col-6 col-md-3 p-2">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                Character Design
                </h6>
            </div>
        </div>
        <div className="col-6 col-md-3 p-2">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                AR & VR Animations
                </h6>
            </div>
        </div>
        <div className="col-6 col-md-3 p-2">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                Gaming and Apps
                </h6>
            </div>
        </div>
        <div className="col-6 col-md-3 p-2">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                Pre-Production Services
                </h6>
            </div>
        </div>
        <div className="col-6 col-md-3 p-2">
            <div className="box d-flex justify-content-center align-items-center">
                <h6 className="text-center fw-normal">
                Post-Production & Editing
                </h6>
            </div>
        </div>
    </div>
    </div>
    </div>


                    





            {/* Retainer-Based and Subscription Services */}




            

        <div className='row mt-4 mb-4 px-sm-2'>
                <div className='col-10 mx-auto'>
                    <div className='text-center pt-5 pb-5'>
                        <h2 className='fw-500 capitalize'>
                            Partner with us
                        </h2>

                        <div className='my-3'>
                            <div className="d-inline-flex rounded-5 border-dark-2px px-3 py-1 cta-button dark-text" ><a href="/contact" className='fs-20 fw-700 capitalize dark-text'>Contact Us</a></div>
                                </div>

                    </div>



                </div>
            </div>


            <div className='row mt-4 mb-4 px-sm-2'>
                <div className='col-10 mx-auto'>
                    <div className='text-center pt-5 pb-5'>
                        <h2 className='fw-400'>
                        Our expertly crafted video, design and development productions not only grab attention but also enhance user experience and engagement.
                        </h2>
                    </div>
                </div>
            </div>



            



            <div className='row px-4 mb-5'>
               <div className='col-md col-sm col-xs-12 mt-3'>
                <p className='fs-18 m-0 p-0 fw-400'>Reach out</p>
                  <a className='fs-32 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art&nbsp;↗</a>
                    <br></br>
                        <a className='fs-32 text-dark fw-500' href="https://api.whatsapp.com/send?phone=972528450488">Whatsapp&nbsp;↗</a>
               </div>
               </div>




            
               </div>

   
        </div>

     
  );
};

export default HomePage;
